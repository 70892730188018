<template>
  <form class="filters filters--facility" @submit.prevent="applyFilters">
    <div class="filters__inner">
      <div class="filters__group" v-for="(group, index) in list" :key="index">
        <p class="filters__group-name" @click="toggleExpand(index, true)">
          {{ group.name }}
          <span class="filters__chevron" :class="{ 'active': hasSelectedItems[index], 'collapse': group.collapse }">
            <img src="@/assets/images/svg/chevron-down.svg" alt="" v-if="!hasSelectedItems[index]">
            <img src="@/assets/images/svg/chevron-down_white.svg" alt="" v-else>
          </span>
        </p>
        <p class="filters__btn" v-if="hasSelectedItems[index]" @click="clearFilter(index, group.name)">Очистить</p>
        <ul :class="{ 'expand': group.expand, 'collapse': group.collapse }" class="filters__options"
          v-if="group.type === 'checkbox'">
          <li v-for="(option, j) in group.options" :key="j">
            <input type="checkbox" class="input-hidden" :id="`${group.name}-${j}`" v-model="option.selected"
              @click="checkboxHandler(option, group.name, option.id)">
            <label :for="`${group.name}-${j}`" class="label-checkbox">{{ option.name }}</label>
          </li>
        </ul>
        <div class="filters__range range" :class="{ 'collapse': group.collapse }" v-if="group.type === 'range'">
          <div class="range__text">
            <input type="text" :value="group.value[0]" @input="changeRangeInput(0, $event.target.value)">
            <span>–</span>
            <input type="text" :value="group.value[1]" @input="changeRangeInput(1, $event.target.value)">
          </div>
          <div class="range__slider">
            <el-slider ref="slider" v-model="group.value" range :min="group.range.start" :max="group.range.end"
              :show-tooltip="group.tooltip" @change="changeRange(group)"></el-slider>
          </div>
        </div>
        <p class="filters__btn" v-if="group.expand !== undefined && !group.expand && !group.collapse"
          @click="toggleExpand(index)">
          Показать все
          <img src="@/assets/images/svg/chevron-right.svg" alt="">
        </p>
      </div>
    </div>
    <div class="filters__footer">
      <button class="filters__btn filters__btn--apply btn btn-green btn-small">
        Применить
        <img src="@/assets/images/svg/chevron-right_white.svg" alt="">
      </button>
      <button class="filters__btn" @click.prevent="clearAll">
        Сбросить фильтры
      </button>
    </div>
  </form>
</template>

<script>
import facilityFilter from '@/helpers/FacilityFilter';
import EncodeDecodeFilter from '@/helpers/EncodeDecodeFilter';

export default {
  name: 'FacilityFilters',
  props: {
    map: Object
  },
  data() {
    return {
      list: [
        {
          name: 'Год сдачи объекта',
          collapse: false,
          type: 'range',
          range: {
            start: 1980,
            end: null
          },
          value: [1980, null],
          tooltip: false,
          touched: false
        }
      ]
    }
  },
  created() {
    const currentYear = new Date().getFullYear();

    if (this.savedRange.length) {
      this.list[0].range.start = this.savedRange[0];
      this.list[0].value[0] = this.savedRange[0];
      this.list[0].range.end = currentYear;
      this.list[0].value[1] = this.savedRange[1];
    } else {
      this.list[0].range.end = currentYear;
      this.list[0].value[1] = currentYear;
    }
    this.getFacilityTypes();
    this.getAvailableProducts();
  },
  mounted() {
    for (const listItem of this.list) {
      for (const filter of this.filterList) {
        if (listItem.name === filter.group) {
          listItem.options = listItem.options.map(e => (filter.names.includes(e.name) ? { ...e, selected: true } : e))
        }
      }
    }
  },
  watch: {
    availables() {
      this.getFacilityTypes();
      this.getAvailableProducts();
    }
  },
  methods: {
    changeRange(group) {
      group.touched = true
      this.$store.dispatch('setObjectRangeFilter', group.value)
    },
    checkboxHandler(option, groupName, groupId) {
      this.$store.dispatch('setObjectsFilter', { name: option.name, groupName, groupId: groupName !== 'Вид объекта' ? groupId : groupId })
    },
    toggleExpand(index, onlyCollapse = false) {
      if (onlyCollapse) {
        if (this.list[index].collapse !== null) {
          this.list[index].collapse = !this.list[index].collapse;
        } else {
          this.list[index].collapse = true;
        }
      }
      if (this.list[index].expand === undefined || (onlyCollapse && !this.list[index].expand)) {
        return;
      }
      this.list[index].expand = !this.list[index].expand;
    },
    clearFilter(index, groupName) {
      this.$store.dispatch('clearObjectFilter', groupName)
      if (groupName === 'Год сдачи объекта') {
        this.$store.dispatch('setObjectRangeFilter', [])
      }

      if (this.list[index].options) {
        this.list[index].options.forEach(option => {
          option.selected = false;
        });
      } else if (this.list[index].type === 'range') {
        const group = this.list[index];
        group.touched = false;
        group.value = [group.range.start, group.range.end];
      }
    },
    clearAll() {
      this.$store.dispatch('clearObjectFilter')
      this.$store.dispatch('setObjectRangeFilter', [])
      this.list.forEach((_, index) => {
        this.clearFilter(index);
      });
    },
    changeRangeInput(index, value) {
      const rangeValue = this.list.find(group => group.type === 'range');
      if (value < rangeValue.range.start || value > rangeValue.range.end) {
        return;
      }
      this.$set(rangeValue.value, index, value);
      this.$store.dispatch('setObjectRangeFilter', rangeValue.value.map(Number))
      this.$refs.slider[0].setValues(rangeValue.value);
    },
    getFacilityTypes() {
      const types = this.$store.getters['getObjectTypes'];
      const findedItem = this.list.find(el => el.name === 'Вид объекта')
      const options = types.map(type => ({ ...type, selected: false }))
      if (findedItem) {
        findedItem.options = options
      } else {
        this.list.unshift({
          type: 'checkbox',
          name: 'Вид объекта',
          options,
          expand: false,
          collapse: null
        });
      }
    },
    getAvailableProducts() {
      const groups = this.$store.getters['getGroups'];
      const activeDirectionIds = this.$store.getters['getDirections'].filter(dir => this.activeDirections.includes(dir.code))
        .map(dir => dir.id);
      const filteredGroup = groups.filter(g => activeDirectionIds.includes(g.direction)).map(group => ({
        id: group.id,
        name: group.name,
        options: this.$store.getters['getProductsByGroup'](group.id).map(product => ({
          ...product,
          selected: false
        })),
        type: 'checkbox',
        expand: false,
        collapse: null,
        icons: false,
        isRolledUp: false
      }))
      this.list = [...this.list.filter(listItem => !listItem.id), ...filteredGroup]
    },
    applyFilters() {
      // const objects = this.$store.getters['getObjects'];
      const filterList = [];
      for (let index = 0; index < this.filterList.length; index++) {
        const element = this.filterList[index];
        filterList.push({ ...element });
      }
      const filterItem = [...this.filterList];
      // const filter = filterList.reduce((a, v) => {
      //  a.typeNames = v.group === 'Вид объекта' ? [...v.names] : a.typeNames
      //  a.productIds = v.productIds.filter(el => el).length ? [...a.productIds, ...v.productIds] : a.productIds
      //  return a
      // }, {
      //  typeNames: [],
      //  productIds: []
      // })
      const encode = EncodeDecodeFilter.encode(this.activeDirectionIds, filterItem, this.savedRange);
      const directionIDs = encode.directionsId;
      const filterIDs = encode.filtersId;
      const range = encode.range;

      if (filterIDs && range) {
        this.$router.push(`/objects/results/${directionIDs}/${filterIDs}/${range}`);
      } else if (filterIDs) {
        this.$router.push(`/objects/results/${directionIDs}/${filterIDs}`);
      } else if (range) {
        this.$router.push(`/objects/results/${directionIDs}/${range}`);
      } else {
        this.$router.push(`/objects/results/${directionIDs}`);
      }
      // const results = facilityFilter(objects, filter, this.savedRange, this.activeDirections)
      // this.$store.dispatch('setSearchResults', results);
      // this.$store.dispatch('applyFilters', results);

      /* if (encode.filtersId && encode.range) {
        this.$router.push(`/results/${this.$store.getters['getActiveLayer']}/${encode.directionsId}/${encode.filtersId}/${encode.range}`);
      } else if (!encode.filtersId && !encode.range) {
        this.$router.push(`/results/${this.$store.getters['getActiveLayer']}/${encode.directionsId}`);
      } else {
        this.$router.push(`/results/${this.$store.getters['getActiveLayer']}/${encode.directionsId}/${encode.filtersId ? encode.filtersId : encode.range}`);
      }
      */

      // if (!results.length) {
      //  this.$services.MessageService.warning('По вашему запросу ничего не найдено');
      // }
    }
  },
  computed: {
    hasSelectedItems() {
      return this.list.map(group => {
        if (group.options && group.options.length) {
          return !!group.options.filter(option => option.selected).length;
        }
        if (group.type === 'range') {
          return group.touched;
        }
        return null;
      });
    },
    activeDirectionIds() {
      return this.directions.filter(dir => this.activeDirections.includes(dir.code)).map(dir => dir.id);
    },
    activeDirections() {
      return this.$store.getters['getActiveDirections'];
    },
    filterList() {
      return this.$store.getters['getObjectFilter']
    },
    availables() {
      return this.$store.getters['getAvailables']
    },
    savedRange() {
      return this.$store.getters['getObjectRangeFilter']
    },
    directions() {
      return this.$store.getters['getDirections']
    }
  }
}
</script>

<style scoped>
.filters {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.filters__inner {
  overflow-y: auto;
  margin-right: 5px;
}

.filters__inner>.filters__group:first-child {
  margin-top: 10px;
}

.filters__inner::-webkit-scrollbar {
  width: 5px;
}

.filters__inner::-webkit-scrollbar-thumb {
  background-color: black;
}

.filters__footer {
  margin-top: auto;
  padding: 20px 15px;
  border-top: 1px solid #BFBFBF;
  display: flex;
  justify-content: left;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: #fff;
}

.filters__group {
  padding: 0 10px;
  margin-bottom: 20px;
}

.filters__group-name {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}

.filters__chevron {
  display: inline-flex;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition: background-color .5s ease;
}

.filters__chevron.active {
  background-color: #58BCAF;
}

.filters__chevron img {
  width: 12px;
  height: 8px;
  transform: rotate(0deg);
  transition: transform .3s ease;
}

.filters__chevron.collapse img {
  transform: rotate(-90deg);
}

.filters__options {
  margin-top: 10px;
}

.filters__options.collapse,
.filters__range.collapse {
  display: none;
}

.filters__options li:nth-child(n+5) {
  display: none;
}

.filters__options.expand li:nth-child(n+5) {
  display: block;
}

.input-hidden {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.label-checkbox {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 5px 0 5px 29px;
  font-size: 18px;
  line-height: 20px;
  background-color: #fff;
  transition: background-color .3s ease;
  cursor: pointer;
}

.label-checkbox:hover {
  background-color: #E5E5E5;
}

.label-checkbox::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 19px;
  height: 19px;
  border: 1px solid #000000;
  background-color: #fff;
}

.label-checkbox::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: url("../assets/images/svg/checked.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

input:checked+.label-checkbox::before {
  background-color: #000;
}

input:checked+.label-checkbox::after {
  opacity: 1;
}

.filters__btn {
  font-size: 18px;
  line-height: 24px;
  color: #DC143C;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filters__btn:not(:last-child) {
  margin-right: 20px;
}

.filters__btn--apply {
  color: #fff;
}

.filters__btn img {
  width: 8px;
  height: 12px;
  margin-left: 8px;
}

.filters__range {
  margin-top: 10px;
}

.range__text {
  margin-bottom: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  ;
}

.range__text input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #BFBFBF;
}

.range__text span {
  margin: 0 10px;
}

.range__slider {
  padding: 0 9px;
}

@media (max-width: 1023px) {
  .filters {
    height: calc(100vh - 180px);
  }
}
</style>
